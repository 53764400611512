import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './RiseUpStyles.scss'

class RiseUp extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
    }
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({hidden: false})
    }, this.props.delay)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  render() {
    let classes = "rise-up"
    if (this.state.hidden) {
      classes += " hidden"
    }
    return (
      <div className={classes}>
        <div className="children-outer-wrapper">
          <div className="children-inner-wrapper">
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

RiseUp.defaultProps = {
  delay: 1000,

}

RiseUp.propTypes = {
  delay: PropTypes.number,
  children: PropTypes.node.isRequired
}

export default RiseUp